import * as React from "react"
import Header from "./header"
import "./layout.css"

export default function Layout(props) {
  return (
    <div className="container">
      <Header indexPage={props.indexPage} siteTitle="MARS Group" />
      <div className="pageContent">{props.children}</div>
      <footer>© {new Date().getFullYear()}</footer>
    </div>
  )
}
