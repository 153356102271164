import * as React from "react"
import * as style from "./nav.module.css"
import AniLink from "gatsby-plugin-transition-link/AniLink"

/*CSS media queries control when to display topnav vs mobile menu button */
/*This component controls the visibility of the mobile nav (toggled on mobile nav button press) */
function TopNav(props) {
  const [showMobileNav, setShowMobileNav] = React.useState(false)
  function toggleMobileMenu() {
    setShowMobileNav(showMobileNav === false) //toggle showMenu value
  }
  return (
    <>
      <button className={style.navMobileBttn} onClick={toggleMobileMenu}>
        <svg viewBox="0 0 100 80" width="40" height="40">
          <rect width="100" height="15" fill="#fff"></rect>
          <rect y="30" width="100" height="15" fill="#fff"></rect>
          <rect y="60" width="100" height="15" fill="#fff"></rect>
        </svg>
      </button>
      {showMobileNav && (
        <div className={style.mobileNav}>
          <ul>{props.children}</ul>
        </div>
      )}
      <div className={style.topnav}>
        <ul>{props.children}</ul>
      </div>
    </>
  )
}

function NavLink(props) {
  return (
    <li>
      <AniLink
        cover
        bg="#003861"
        to={props.to || "/"}
        className={props.isActive ? style.active : undefined}
      >
        {props.children}
      </AniLink>
    </li>
  )
}

function SideNav(props) {
  return <div className={style.sidebar}>{props.children}</div>
}

export { TopNav, SideNav, NavLink }
