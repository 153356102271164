import * as React from "react"
import AniLink from "gatsby-plugin-transition-link/AniLink"
import { TopNav, NavLink } from "./nav"
import * as style from "./header.module.css"
import logo from "../images/mars-logo.svg"

// Check if window is defined (so if in the browser or in node.js).
const isBrowser = typeof window !== "undefined"

export default function Header(props) {
  const location = isBrowser ? window.location.pathname : ""
  return (
    <div className={props.indexPage ? style.indexPageHeader : style.header}>
      <div className={style.headerContent}>
        <AniLink swipe top="entry" to="/" entryOffset={80}>
          <img src={logo} alt={props.siteTitle} className={style.logo} />
        </AniLink>
        <TopNav>
          <NavLink
            name="facilities"
            to="/facilities"
            isActive={location === "/facilities"}
          >
            Facilities
          </NavLink>
          <NavLink
            name="testimonials"
            to="/testimonials"
            isActive={location === "/testimonials"}
          >
            Testimonials
          </NavLink>
          <NavLink name="about" to="/about" isActive={location === "/about"}>
            About
          </NavLink>
          <NavLink
            name="trainings"
            to="/trainings"
            isActive={location === "/trainings"}
          >
            Trainings
          </NavLink>
          <NavLink
            name="employment"
            to="/employment"
            isActive={location === "/employment"}
          >
            Employment
          </NavLink>
          <NavLink
            name="contact"
            to="/contact"
            isActive={location === "/contact"}
          >
            Contact
          </NavLink>
        </TopNav>
      </div>
    </div>
  )
}
